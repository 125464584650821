<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.647" x2="0.25" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_favourites" transform="translate(-1860 -2564)">
    <g id="icon_favourite" transform="translate(-214 -204)">
     <rect :fill="background" data-name="Rectangle 193" height="183" id="Rectangle_193" transform="translate(2074 2768)" width="183"/>
     <g data-name="Group 449" id="Group_449" transform="translate(7.661 -11.946)">
      <path d="M63.792,91.392l-9.321-8.976H17a17,17,0,0,1-17-17V17A17,17,0,0,1,17,0h97.677a17,17,0,0,1,17,17V65.417a17,17,0,0,1-17,17H80.05l-9.321,8.976a5,5,0,0,1-6.937,0Z" data-name="Union 6" fill="url(#linear-gradient)" id="Union_6" transform="translate(2091.999 2830)"/>
      <path d="M28.481,28.481A11.99,11.99,0,1,0,16.49,16.49,12.025,12.025,0,0,0,28.481,28.481Zm0,6c-7.943,0-23.981,4.047-23.981,11.99v6H52.462v-6C52.462,38.523,36.424,34.476,28.481,34.476Z" data-name="Icon ionic-md-person" fill="#fff" id="Icon_ionic-md-person" transform="translate(2153.338 2844.18)"/>
      <g data-name="Group 448" id="Group_448" transform="translate(2112.12 2845.082)">
       <path d="M16.192,1.1,12.159,9.278,3.134,10.594a1.978,1.978,0,0,0-1.093,3.373L8.57,20.329,7.025,29.317A1.976,1.976,0,0,0,9.892,31.4l8.073-4.244L26.038,31.4A1.977,1.977,0,0,0,28.9,29.317L27.36,20.329l6.529-6.362A1.978,1.978,0,0,0,32.8,10.594L23.771,9.278,19.738,1.1A1.978,1.978,0,0,0,16.192,1.1Z" data-name="Icon awesome-star" fill="#fff" id="Icon_awesome-star" transform="translate(-1.441 17.315)"/>
       <path d="M2126.3,2850.9a2.5,2.5,0,0,1-1.966-.954l-4.237-5.384a2.5,2.5,0,1,1,3.929-3.092l4.237,5.384a2.5,2.5,0,0,1-1.963,4.046Z" data-name="Path 552" fill="#fff" id="Path_552" transform="translate(-2117.833 -2836.1)"/>
       <path d="M2132.5,2849.731a2.5,2.5,0,0,1-2.5-2.5v-9.607a2.5,2.5,0,0,1,5,0v9.607A2.5,2.5,0,0,1,2132.5,2849.731Z" data-name="Path 553" fill="#fff" id="Path_553" transform="translate(-2115.717 -2837.624)"/>
       <path d="M2137.156,2850.726a2.5,2.5,0,0,1-1.888-4.138l5.276-6.086a2.5,2.5,0,1,1,3.778,3.275l-5.276,6.086A2.5,2.5,0,0,1,2137.156,2850.726Z" data-name="Path 554" fill="#fff" id="Path_554" transform="translate(-2112.052 -2835.927)"/>
      </g>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>